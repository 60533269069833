export default {
  "page_users_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users"])},
  "page_users_users_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "page_users_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
  "page_users_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "page_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "page_users_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email address"])},
  "page_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
  "page_users_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "page_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "page_users_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login"])},
  "page_users_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "page_users_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
  "page_users_language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "page_users_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "page_users_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "page_users_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic-ID enabled"])},
  "page_users_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Step enabled"])},
  "page_users_search_email_or_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search email or name"])},
  "page_users_bulk_email_domain_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk email domain change"])},
  "page_users_user_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User export"])},
  "page_users_modal_domain_change_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning - user accounts will be changed"])},
  "page_users_modal_domain_change_body_n_users_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to change ", _interpolate(_named("usersCount")), " user accounts from ", _interpolate(_named("domainsCount")), " domains"])},
  "page_users_modal_domain_change_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to change 1 user account from 1 domain"])},
  "page_users_modal_domain_change_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to change ", _interpolate(_named("usersCount")), " user accounts from 1 domain"])},
  "page_users_modal_domain_change_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you tell the users about the changes"])},
  "page_users_modal_domain_change_source_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source domains"])},
  "page_users_modal_domain_change_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target domain"])},
  "page_users_modal_domain_change_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change domain"])},
  "page_users_modal_domain_change_not_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no target domains available. Uncheck one of the source domains and try again"])},
  "page_users_modal_domain_change_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I acknowledge that this action will modify selected user accounts"])},
  "page_users_modal_domain_change_example_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
  "page_users_modal_domain_change_example_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["john.doe", "@", _interpolate(_named("sourceDomain")), " will be changed to john.doe", "@", _interpolate(_named("targetDomain"))])},
  "page_users_modal_export_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export users"])},
  "page_users_modal_export_users_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export users"])},
  "page_users_modal_export_users_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
  "page_users_modal_export_users_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 user from 1 domain will be exported"])},
  "page_users_modal_export_users_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " users from 1 domain will be exported"])},
  "page_users_modal_export_users_body_n_user_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " users from ", _interpolate(_named("domainsCount")), " domains will be exported"])},
  "page_users_modal_export_users_select_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the columns of data you'd like to export"])},
  "page_users_modal_export_users_field_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
  "page_users_modal_export_users_field_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "page_users_modal_export_users_field_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "page_users_modal_export_users_field_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "page_users_modal_export_users_field_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "page_users_modal_export_users_field_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "page_users_modal_export_users_field_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "page_users_modal_export_users_field_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ElectronicID Enabled"])},
  "page_users_modal_export_users_field_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Step Authentication Enabled Date"])},
  "page_users_modal_export_users_field_applications_selected_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: You are exporting applications for a user. The export will create one row per user/application, so that the same user will be returned on multiple-rows if it has more than 1 application."])},
  "page_users_clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear selection"])},
  "page_users_select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "page_users_add_to_selection_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all to selection"])},
  "page_users_select_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all from page"])},
  "page_users_add_to_selection_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all from page to selection"])},
  "page_users_select_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all from filter"])},
  "page_users_add_to_selection_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all from filter to selection"])},
  "page_users_selected_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selected (", _interpolate(_named("usersCount")), ")"])},
  "page_users_select_usercount_from_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("usersCount")), " users from filter"])},
  "page_users_select_usercount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("usersCount")), " users from page"])},
  "page_select_all_usercount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select all ", _interpolate(_named("usersCount")), " users"])},
  "page_users_modal_export_users_export_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "page_users_modal_export_users_column_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column name"])},
  "page_users_1_selected_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Domain"])},
  "page_users_selected_domains_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("domainsCount")), " Domains"])},
  "page_users_all_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All domains"])},
  "page_users_filter_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter domains"])},
  "page_users_clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])},
  "page_users_add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
  "page_users_import_or_update_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import users"])},
  "page_users_modal_import_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import or update users"])},
  "page_users_modal_import_users_upload_file_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose file"])},
  "page_users_modal_import_users_import_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import or update users"])},
  "page_users_modal_import_users_select_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a CSV file"])},
  "page_users_modal_import_users_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required fields are user_id, email, first_name, last_name, country and language. All the leading and trailing spaces will be removed"])},
  "page_users_modal_import_users_specify_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always specify a value for the user_id, if you wish to update user accounts, otherwise a new user will be created"])},
  "page_users_modal_import_users_add_edit_user_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add or edit user info in the CSV template"])},
  "page_users_modal_import_users_download_csv_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV template"])},
  "page_users_modal_import_users_download_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV template"])},
  "page_users_modal_import_users_upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV file"])},
  "page_users_modal_import_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll need to complete these steps in order to import or update users"])},
  "page_users_modal_import_users_mandatory_column_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more mandatory columns are missing: "])},
  "page_users_modal_import_users_invalid_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One ore more columns are invalid:"])},
  "page_users_modal_import_users_invalid_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid number of columns at line: "])},
  "page_users_modal_import_users_invalid_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header is missing or is invalid"])},
  "page_users_modal_import_users_invalid_csv_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CSV file content"])},
  "page_users_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "page_users_modal_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "page_users_modal_all_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All countries"])},
  "page_users_modal_all_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All languages"])},
  "page_users_modal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "page_users_modal_add_user_invalid_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid first name"])},
  "page_users_modal_add_user_invalid_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid last name"])},
  "page_users_modal_add_user_invalid_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid language"])},
  "page_users_modal_add_user_invalid_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid country"])},
  "page_users_modal_add_user_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
  "page_users_modal_add_user_email_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists"])},
  "page_users_modal_add_user_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added successfully"])},
  "page_users_modal_add_user_success_otp_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was created successfully and has the above one-time password."])},
  "page_users_modal_add_user_success_email_link_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with login instructions was sent to user email address."])},
  "page_users_modal_add_user_auto_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-generate one time password"])},
  "page_users_modal_add_user_send_email_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send account activation email to user"])},
  "page_users_modal_add_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while adding a user."])},
  "page_users_modal_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select domain"])},
  "page_user_details_back_to_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to users"])},
  "page_user_details_tab_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "page_user_details_tab_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "page_user_details_tab_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
  "page_user_details_tab_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit logs"])},
  "page_user_details_tab_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "page_user_details_tab_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "page_user_details_tab_email_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email history"])},
  "page_user_details_tab_passwordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwordless"])},
  "page_user_details_tab_linked_eids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked eIDs"])},
  "page_user_details_tab_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "page_user_details_recovery_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email addresses"])},
  "page_user_details_phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
  "page_user_details_edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
  "page_user_details_update_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while updating the user"])},
  "page_user_details_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application name"])},
  "page_user_details_authorization_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization date"])},
  "page_user_details_login_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login date"])},
  "page_user_details_login_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login count"])},
  "page_user_details_no_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No applications"])},
  "page_user_details_showing_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " out of ", _interpolate(_named("totalCount")), " apps"])},
  "page_user_details_showing_application": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Showing ", _interpolate(_named("count")), " out of 1 app"])},
  "page_user_details_show_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show ", _interpolate(_named("count")), " supporting apps"])},
  "page_user_details_hide_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hide ", _interpolate(_named("count")), " supporting apps"])},
  "page_user_details_show_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show 1 supporting app"])},
  "page_user_details_hide_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide 1 supporting app"])},
  "page_user_details_update_user_profile_image_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image file is invalid or corrupted and could not be accepted"])},
  "page_user_details_update_user_profile_edit_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile picture"])},
  "page_user_details_update_user_profile_choose_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose photo ..."])},
  "page_user_details_update_user_profile_image_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image file dimensions are too small. The minimum accepted are 200px width and 200px height"])},
  "page_user_details_reset_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "page_user_details_reset_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to send a reset password email to the user?"])},
  "page_user_details_reset_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password reset link is sent to user's primary and recovery email addresses. The password is not automatically reset. The password reset link will expire 15 minutes after it is sent."])},
  "page_user_details_one_time_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time password"])},
  "page_user_details_one_time_password_modal_body_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The one-time password is:"])},
  "page_user_details_one_time_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to generate a one-time password for user?"])},
  "page_user_details_one_time_password_modal_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will be required to set a new password as soon as they log in."])},
  "page_users_modal_generate_emergency_code_totp_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user does not have TOTP enabled."])},
  "page_users_modal_generate_emergency_code_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-Time Emergency code"])},
  "page_user_details_generate_emergency_code_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to generate an one-time emergency code for user?"])},
  "page_users_modal_generate_emergency_code_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The one-time emergency code is:"])},
  "page_users_modal_generate_emergency_code_how_to_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose how to generate"])},
  "page_users_modal_generate_emergency_code_to_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate a new one-time emergency code to copy"])},
  "page_users_modal_generate_emergency_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the one-time emergency code to the user's inbox"])},
  "page_users_modal_generate_emergency_code_by_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with one-time emergency code was sent to user's inbox."])},
  "page_users_modal_generate_emergency_code_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "page_users_modal_generate_emergency_code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "page_user_details_more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More actions"])},
  "page_user_details_send_reset_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reset password email"])},
  "page_user_details_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate one-time password"])},
  "page_user_details_generate_one_time_emergency_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate one-time emergency code"])},
  "page_user_details_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "page_user_details_last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last used"])},
  "page_user_details_never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never used"])},
  "page_user_details_last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last changed"])},
  "page_user_details_password_not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password not set"])},
  "page_user_reset_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reset password request"])},
  "page_user_not_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not requested"])},
  "page_user_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password locked"])},
  "page_user_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not locked"])},
  "page_user_2_fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Step Verification"])},
  "page_user_enforced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enforced"])},
  "page_users_enabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
  "page_users_not_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enabled"])},
  "page_users_disabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
  "page_users_last_generated_emergency_code_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last generated one-time emergency code"])},
  "page_users_visma_connect_idp_disabled_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Visma is disabled."])},
  "page_users_visma_connect_idp_disabled_adjust_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please adjust your policy if you want to enable this."])},
  "page_user_details_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "page_user_details_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login"])},
  "page_user_details_never_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never logged in"])},
  "page_user_details_passkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passkeys"])},
  "page_user_details_passkeys_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of passkeys"])},
  "page_user_details_no_passkeys_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No passkeys registered"])},
  "page_users_modal_unlock_password_account_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user account is not password locked."])},
  "page_users_modal_unlock_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock password"])},
  "page_user_details_unlock_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock password"])},
  "page_user_details_unlock_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password was unlocked successfully."])},
  "page_users_details_auto_unlocked_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Auto-unlocked in ", _interpolate(_named("count")), " minutes"])},
  "page_users_details_auto_unlocked_1minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-unlocked in 1 minute"])},
  "page_user_details_unlock_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to unlock password for user?"])},
  "page_user_details_suspend_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend account"])},
  "page_user_details_resume_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume account"])},
  "page_user_details_suspend_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend account"])},
  "page_user_details_suspend_user_modal_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend reason:"])},
  "page_user_details_suspend_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to suspend account?"])},
  "page_users_modal_suspend_user_account_already_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account is already suspended."])},
  "page_users_modal_suspend_user_account_not_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account is not suspended."])},
  "page_users_modal_suspend_user_account_invalid_suspend_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid suspend reason"])},
  "page_user_details_account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account suspended"])},
  "page_user_details_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "page_user_details_resume_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume account"])},
  "page_user_details_resume_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to resume account?"])},
  "page_user_details_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])},
  "page_user_details_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
  "page_user_details_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
  "page_user_details_not_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sessions"])},
  "page_user_details_session_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "page_user_details_session_logout_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout from session"])},
  "page_user_details_session_logout_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to logout user from selected session?"])},
  "page_users_modal_delete_session_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not logout user from selected session"])},
  "page_user_details_current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current session"])},
  "page_user_details_view_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["View all ", _interpolate(_named("applicationsCount")), " apps"])},
  "page_user_details_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "page_user_details_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "page_user_details_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload"])},
  "page_users_no_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No audit"])},
  "page_users_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audit log"])},
  "page_users_audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audit logs"])},
  "page_users_selected_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selected (", _interpolate(_named("auditCount")), ")"])},
  "page_users_select_auditcount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("auditCount")), " audit logs from page"])},
  "page_select_all_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select all ", _interpolate(_named("auditCount")), " audit logs"])},
  "page_users_audit_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "page_users_audit_event_login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
  "page_users_audit_event_login_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login succeeded"])},
  "page_users_audit_event_client_application_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application authorized"])},
  "page_users_audit_event_logout_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout succeeded"])},
  "page_users_1_selected_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 event"])},
  "page_users_selected_events_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " events"])},
  "page_users_all_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All events"])},
  "page_users_filter_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter events"])},
  "page_users_modal_export_audit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export audit logs"])},
  "page_users_modal_export_audit_body_1_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 audit log will be exported"])},
  "page_users_modal_export_users_body_n_audit_logs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " audit logs will be exported"])},
  "page_users_modal_export_user_audit_logs_field_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit ID"])},
  "page_users_modal_export_user_audit_logs_field_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "page_users_modal_export_user_audit_logs_field_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "page_users_modal_export_user_audit_logs_field_ipv4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])},
  "page_users_modal_export_user_audit_logs_field_application_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application ID"])},
  "page_users_modal_export_user_audit_logs_field_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application name"])},
  "page_users_modal_export_user_audit_logs_field_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload"])},
  "page_users_modal_export_audit_logs_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export audit logs"])},
  "page_users_audit_search_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while searching for audit logs"])},
  "page_users_modal_export_audit_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while exporting audit logs"])},
  "page_user_details_add_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a recovery email address"])},
  "page_user_details_add_another_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another recovery email address"])},
  "page_user_details_update_user_invalid_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid recovery email"])},
  "page_user_details_update_user_recovery_email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email address is duplicated"])},
  "page_user_details_update_user_recovery_email_disposable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email address is using a disposable domain"])},
  "page_user_push_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push notifications"])},
  "page_user_push_instances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push instances"])}
}