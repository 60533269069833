import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import UserPhoneModel from './UserPhoneModel';
import UserRecoveryEmailModel from './UserRecoveryEmailModel';
import BaseUserDetailsModel from './Base/BaseUserDetailsModel';

@JsonObject('UserProfileModel')
export default class UserProfileModel extends BaseUserDetailsModel {
    public constructor() {
        super();

        this.id = '';
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.languageCode = '';
        this.twoStepEnforced = false;
        this.loginCount = 0;
        this.passKeysCount = 0;
        this.twoStepPushInstancesCount = 0;
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('first_name', String)
    public firstName: string;

    @JsonProperty('last_name', String)
    public lastName: string;

    @JsonProperty('country_code', String, true)
    public countryCode?: string;

    @JsonProperty('preferred_language', String)
    public languageCode: string;

    @JsonProperty('phones', [UserPhoneModel], true)
    public phones?: UserPhoneModel[];

    @JsonProperty('recovery_emails', [UserRecoveryEmailModel], true)
    public recoveryEmails?: UserRecoveryEmailModel[];

    @JsonProperty('password_last_login_date', String, true)
    public lastPasswordLoginDate?: string;

    @JsonProperty('password_changed_date', String, true)
    public lastPasswordChangedDate?: string;

    @JsonProperty('password_reset_key_generated', String, true)
    public lastPasswordResetDate?: string;

    @JsonProperty('password_account_locked_date', String, true)
    public accountPasswordLockedDate?: string;

    @JsonProperty('2step_enabled_date', String, true)
    public twoStepEnabledDate?: string;

    @JsonProperty('2step_emergencycode_generated_date', String, true)
    public twoStepEmergencyCodeGeneratedDate?: string;

    @JsonProperty('2step_disabled_date', String, true)
    public twoStepDisabledDate?: string;

    @JsonProperty('2step_enforced', Boolean, true)
    public twoStepEnforced: boolean;

    @JsonProperty('vismaconnect_idp_enabled', Boolean, true)
    public hasVismaConnectIdpEnabled?: boolean;

    @JsonProperty('login_date', String, true)
    public loginDate?: string;

    @JsonProperty('login_count', Number)
    public loginCount: number;

    @JsonProperty('passkey_count', Number)
    public passKeysCount: number;

    @JsonProperty('passkey_last_login_date', String, true)
    public lastPasskeyLoginDate?: string;

    @JsonProperty('minutes_until_password_unlocked', Number, true)
    public minutesUntilPasswordUnlocked?: number;

    @JsonProperty('suspended_date', String, true)
    public suspendedDate?: string;

    @JsonProperty('suspended_reason', String, true)
    public suspendedReason?: string;

    @JsonProperty('2step_push_instance_count', Number)
    public twoStepPushInstancesCount: number;

    @JsonProperty('2step_push_instance_last_login_date', String, true)
    public lastTwoStepPushInstancLoginDate?: string;

    public get userName(): string {
        return this.email.slice(0, this.email.indexOf('@'));
    }

    public get domain(): string {
        return this.email.slice(this.email.indexOf('@') + 1);
    }

    public get displayLoginDateTime(): string {
        if (!this.loginDate) {
            return '';
        }

        return util.formatDateTime(this.loginDate) + ' UTC';
    }

    public get displaySuspendedDateTime(): string {
        if (!this.suspendedDate) {
            return '';
        }

        return util.formatDateTime(this.suspendedDate) + ' UTC';
    }

    public get displayLastPasskeyLoginDateTime(): string {
        if (!this.lastPasskeyLoginDate) {
            return '';
        }

        return util.formatDateTime(this.lastPasskeyLoginDate) + ' UTC';
    }

    public get displayLastPasswordLoginDateTime(): string {
        if (!this.lastPasswordLoginDate) {
            return '';
        }

        return util.formatDateTime(this.lastPasswordLoginDate) + ' UTC';
    }

    public get displayLastPasswordChangedDateTime(): string {
        if (!this.lastPasswordChangedDate) {
            return '';
        }

        return util.formatDateTime(this.lastPasswordChangedDate) + ' UTC';
    }

    public get displayLastPasswordResetDateTime(): string {
        if (!this.lastPasswordResetDate) {
            return '';
        }

        return util.formatDateTime(this.lastPasswordResetDate) + ' UTC';
    }

    public get displayAccountPasswordLockedDateTime(): string {
        if (!this.accountPasswordLockedDate) {
            return '';
        }

        return util.formatDateTime(this.accountPasswordLockedDate) + ' UTC';
    }

    public get displayTwoStepEnabledDateTime(): string {
        if (!this.twoStepEnabledDate) {
            return '';
        }

        return util.formatDateTime(this.twoStepEnabledDate) + ' UTC';
    }

    public get displayTwoStepDisabledDateTime(): string {
        if (!this.twoStepDisabledDate) {
            return '';
        }

        return util.formatDateTime(this.twoStepDisabledDate) + ' UTC';
    }

    public get displayTwoStepEmergencyCodeGeneratedDateTime(): string {
        if (!this.twoStepEmergencyCodeGeneratedDate) {
            return '';
        }

        return util.formatDateTime(this.twoStepEmergencyCodeGeneratedDate) + ' UTC';
    }

    public get displayLastTwoStepPushInstanceLoginDateTime(): string {
        if (!this.lastTwoStepPushInstancLoginDate) {
            return '';
        }

        return util.formatDateTime(this.lastTwoStepPushInstancLoginDate) + ' UTC';
    }
}
